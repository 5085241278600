import { Button, Card, CardActions, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogContentText, Divider, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { userContext } from "Components/User";
import React, { useContext, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import UserAvatar from "Components/User/avatar";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentService from "services/Payment";
import SubscriptionService from "services/Subscription";
import LoadingButton from "Components/Atoms/LoadingButton";
import utils from "utils";
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from "react-i18next";

export const Settings = () => {
  const { t, i18n } = useTranslation();
  const mTheme = useTheme();
  const uContext = useContext(userContext);
  const navigate = useNavigate();
  const [cancelSubsctiptionModal, setCancelSubsctiptionModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isTrial, setIsTrial] = useState(true);
  const [payments, setPayments] = useState([]);
  const [startAfterPayment, setStartAfterPayment] = useState(null);
  const [loadMore, setLoadMore] = useState(true);
  const [loadPayments, setLoadPayments] = useState(false);
  const [createSessionInProgress, setCreateSessionInProgress] = useState(false);
  const [cancelSubscriptionInProgress, setCancelSubscriptionInProgress] = useState(false);

  useEffect(() => {
    // console.log("Settings: uContext: ", uContext);
    setLoading(!uContext.subscription);
    setIsTrial(uContext.isTrial);
  }, [uContext.subscription]);

  const toLocalDate = (date) => {
    return new Date(date).toLocaleDateString(
      i18n.language,
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
    )
  }

  useEffect(() => {
    if (!loadMore) {
      return;
    }
    setLoadMore(false);
    setLoadPayments(true);
    PaymentService.getPayments(startAfterPayment).then((data) => {
      setPayments([...payments, ...data.payments]);
      setStartAfterPayment(data.starting_after);
    }).finally(() => {
      setLoadPayments(false);
    });
  }, [loadMore]);

  const createSubscriptionSession = () => {
    navigate(`/${i18n.language}/checkout`)
  };

  const handleClose = () => {
    setCancelSubsctiptionModal(false);
  };

  const cancelSubscription = () => {
    setCancelSubscriptionInProgress(true);
    SubscriptionService.cancelSubscription().then(() => {
      navigate(`/${i18n.language}/subscription/cancelled`)
    }).finally(() => {
      setCancelSubscriptionInProgress(false);
      handleClose();
    });
  }

  const profileDetailsPanel = () => (
    <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <UserAvatar size={52} />
      <Typography variant="h6">{t("settings.welcome")} {uContext.name}</Typography>
      <Typography textAlign={"center"} sx={{ textWrap: "pretty" }}>{t("settings.manage")}</Typography>
    </Container>
  );

  const trialPanel = () => {
    const isExpired = new Date(uContext.subscription.expires_at) < new Date();
    const expirationDate = new Date(uContext.subscription.expires_at).toLocaleDateString();
    return (
      <>
        <Typography gutterBottom>
          {isExpired ?
            t("settings.trialExpired", { days: 30, expirationDate: expirationDate })
            :
            t("settings.trialOn", { days: 30, expirationDate: expirationDate })
          }
        </Typography>
        <Typography>
          {t("settings.upgrade")}
        </Typography>
      </>
    );
  };

  const subscriptionPanel = () => {
    return (
      <Card variant="outlined">
        <CardHeader
          title={<>
            <Typography variant="h6" component="div">{t("settings.subscription")}</Typography>
            <Divider />
          </>}
          avatar={<PersonIcon />} />
        <CardContent>
          {loading ?
            <Skeleton sx={{ height: "3em", }} /> :
            isTrial ? trialPanel() : subscriptionInfoPanel()}
        </CardContent>
        <CardActions sx={{ justifyContent: loading || isTrial ? "center" : "end" }}>
          {loading ?
            <Skeleton variant="rectangular" sx={{ height: "2em", width: "15em" }} /> :
            isTrial || uContext.subscription?.status === "cancelled" ?
              <LoadingButton
                inProgress={createSessionInProgress}
                onClick={createSubscriptionSession}
                buttonSx={{ maxWidth: "400px" }}
                boxSx={{ width: "100%" }}
                buttonFullWidth={true}
                text={t("settings.subscribe")}
              />
              :
              <Button
                size="small"
                onClick={() => setCancelSubsctiptionModal(true)}
              >
                {t("settings.cancelSubscription")}
              </Button>}
        </CardActions>
      </Card >
    );
  };

  const activeSubscription = () => (
    <>
      <Typography gutterBottom>{t("settings.activeFrom", { startDate: toLocalDate(uContext.subscription.created_at) })}</Typography>
      <Typography gutterBottom>{t("settings.renewDate", { endDate: toLocalDate(uContext.subscription.expires_at) })}</Typography>
      <Typography>{t("settings.weAppreciate")}</Typography>
    </>
  )

  const cancelledSubscription = () => {
    const isExpired = new Date(uContext.subscription.expires_at) < new Date();
    const text = isExpired ?
      t("settings.subscriptionCancelled") :
      t("settings.subscriptionWillBeCanceled", { endDate: toLocalDate(uContext.subscription.expires_at) })
    return (
      <>
        <Typography gutterBottom>{text}</Typography>
        <Typography>{t("settings.subscribeAgain")}</Typography>
      </>
    );
  }

  const subscriptionInfoPanel = () => {
    const isCancelled = uContext.subscription.status === "cancelled";
    return isCancelled ? cancelledSubscription() : activeSubscription();
  };

  const paymentsInfoPanel = () => {
    if (payments.length === 0) {
      return (<Typography>{t("settings.noPaymentsYet")}</Typography>);
    }
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("settings.date")}</TableCell>
              <TableCell align="right">{t("settings.amount")}</TableCell>
              <TableCell align="right">{t("settings.viewReceipt")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment) => (
              <TableRow
                key={payment.created}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell >
                  {toLocalDate(payment.created * 1000)}
                </TableCell>
                <TableCell align="right">{utils.formatCurrency(payment.currency, payment.amount)}</TableCell>
                <TableCell align="right" sx={{ alignItems: "center" }}>
                  {/* <Button

                                        href={payment.receipt_url}
                                        target="_blank"
                                        endIcon={<LaunchIcon sx={{ color: mTheme.palette.text.primary }} />}
                                    /> */}
                  <IconButton
                    href={payment.receipt_url + "&locale=" + i18n.language}
                    target="_blank"
                  >
                    <LaunchIcon
                      fontSize="small"
                      sx={{ color: mTheme.palette.text.primary }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>)
  };

  const paymentsPanel = () => {
    return (
      <Card variant="outlined">
        <CardHeader
          title={<>
            <Typography variant="h6" component="div">{t("settings.payments")}</Typography>
            <Divider />
          </>}
          avatar={<CreditCardIcon />} />
        <CardContent>
          {loading || loadPayments ?
            <Skeleton sx={{ height: "3em", }} /> :
            isTrial ?
              <>
                <Typography gutterBottom>{t("settings.enjoyExporation")}</Typography>
                <Typography gutterBottom>{t("settings.trialIsOnUs")}</Typography>
              </>
              :
              paymentsInfoPanel()}
        </CardContent>
        {!isTrial && !loading && !!payments.length && startAfterPayment &&
          <CardActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={() => setLoadMore(true)}
            >
              {t("settings.loadMore")}
            </Button>
          </CardActions>}
      </Card>
    );
  };

  return (
    <>
      <Container sx={{ flexGrow: "1", display: "flex", justifyContent: "center", mt: "1em" }}>
        <Grid id="settings" xs={12} sm={10} md={8} spacing={3} sx={{ mt: "1em", mb: "1em", minWidth: "250px" }} container direction={"column"}>
          <Grid display={"flex"} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
            {profileDetailsPanel()}
          </Grid>
          <Grid>
            {subscriptionPanel()}
          </Grid>
          <Grid>
            {paymentsPanel()}
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={cancelSubsctiptionModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("settings.areYouSureToCancel")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>{t("global.no")}</Button>
          <LoadingButton
            inProgress={cancelSubscriptionInProgress}
            onClick={cancelSubscription}
            text={t("settings.cancelSubscription")}
            buttonVariant={"text"}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Settings;