
import i18n from "i18next";

const CSL_EMAIL = "CommonSenseLabs@gmail.com";
const ZERO_DECIMAL_CURRENCIES = [
  'BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF'
];

const formatCurrency = (currency, amount) => {
  if (!ZERO_DECIMAL_CURRENCIES.includes(currency.toUpperCase())) {
    amount /= 100
  }
  return Intl.NumberFormat(
    i18n.language,
    {
      style: "currency",
      currency: currency
    }
  ).format(amount);
};

const addLngPath = (name) => {
  const path = window.location.pathname;
  return (path.split("/")[1] || 'en') + name; // Default to English if no path
};

const toLocaleDateString = (date) => {
  return new Date(date).toLocaleDateString()
}

const utils = {
  formatCurrency,
  addLngPath,
  toLocaleDateString,
  CSL_EMAIL
}


export default utils;