
export class Config {

  static userPoolId = "USER_POOL_ID";
  static userPoolClientId = "USER_POOL_CLIENT_ID";
  static oAuthDomain = "OAUTH_DOMAIN";
  static redirectSignIn = "REDIRECT_SIGN_IN";
  static redirectSignOut = "REDIRECT_SIGN_OUT";
  static stripeApiKey = "STRIPE_API_KEY";
  static baseUrl = "URL"
  static apiBaseUrl = "API_BASE_URL"
  static apiCloudFrontUrl = "API_CLOUDFRONT_URL"

  static getProperty = (property_name) => {
    const react_property_name = `REACT_APP_${property_name}`;
    return process.env[react_property_name];
  }

  static appendDomain = (path, cloudFront = false) => {
    const domain = this.getProperty(cloudFront ? this.apiCloudFrontUrl : this.apiBaseUrl);
    return domain + "/" + path;
  }

  static getReportUrl = () => {
    return Config.appendDomain("reports")
  }

  static searchUrl = () => {
    return Config.appendDomain("search")
  }

  static getSubscriptionsUrl = () => {
    return Config.appendDomain("subscriptions")
  }

  static createCheckoutSessionUrl = () => {
    return this.appendDomain("payments/sessions", true)
    // return Config.appendDomain("payments/sessions")
  }

  static receiptsUrl = (sessionId) => {
    return Config.appendDomain(`payments/sessions/${sessionId}/receipts`)
  }

  static paymentsUrl = () => {
    return Config.appendDomain("payments")
  }

  static getUserPoolId() {
    return Config.getProperty(this.userPoolId)
  }

  static getPriceUrl() {
    return this.appendDomain("prices", true)
  }

  static getUserPoolClientId() {
    return Config.getProperty(this.userPoolClientId)
  }

  static getOauthDomin() {
    return Config.getProperty(this.oAuthDomain)
  }

  static getRedirectSignIn() {
    return Config.getProperty(this.redirectSignIn)
  }

  static getRedirectSignOut() {
    return Config.getProperty(this.redirectSignOut)
  }

  static getStripeApiKey() {
    return Config.getProperty(this.stripeApiKey)
  }

  static getBaseUrl() {
    return Config.getProperty(this.baseUrl)
  }

}
